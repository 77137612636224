import { Typography } from "Components/Own";
import { useMobileScreen } from "Hooks/useMediaQuery";
import { Trans } from "react-i18next";
import { classnames } from "Utils/Classnames.Util";

const TyroNote = () => {
  const isMobile = useMobileScreen();
  return (
    <div
      className={classnames({
        "w-full p-6 md:p-10 bg-white-slate rounded-[24px]": !isMobile,
      })}
    >
      <Typography.Description className="text-center text-grey">
        <Trans
          i18nKey="tyro.tyro_note"
          values={{
            support: "http://tyro.com/support/",
            resolution: "http://tyro.com/complaint-resolution-process/",
          }}
          components={{
            support: (
              <a
                className="underline"
                href="http://tyro.com/support/"
                target="_blank"
              />
            ),
            resolution: (
              <a
                className="underline"
                href="http://tyro.com/complaint-resolution-process/"
                target="_blank"
              />
            ),
          }}
        />
      </Typography.Description>
    </div>
  );
};

export default TyroNote;
